@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --app-background-color: #ffffff;
    --layer-background-color: #f3f4f6;
    --app-text-color: #1c2127;
    --muted-text-color: #5f6b7c;
    --app-border-color: #d1d5db;

    /* https://tailwindcss.com/docs/customizing-colors#using-css-variables */
    --intent-danger-color: 205 66 70;
    --intent-primary-color: 45 114 210;
    --intent-success-color: 35 133 81;
    --intent-warning-color: 200 118 25;
}

.dark {
    --app-background-color: #2f343c;
    --layer-background-color: #252a31;
    --app-text-color: #f6f7f9;
    --muted-text-color: #abb3bf;
    --app-border-color: #111827;

    /* https://tailwindcss.com/docs/customizing-colors#using-css-variables */
    --intent-danger-color: 231 106 110;
    --intent-primary-color: 76 144 240;
    --intent-success-color: 50 164 103;
    --intent-warning-color: 236 154 60;
}

html,
body,
#__next {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
}
